import { SEO } from "components/seo/SEO.component";
import React from "react";

const Embed = ({ pageContext }) => {
  const { title, windowTitle, fileName, contentType, src } = pageContext;
  return (
    <>
      <SEO title={windowTitle ?? title} />
      <embed
        type={contentType}
        src={src}
        style={{
          width: "100vw",
          height: "100vh",
        }}
        title={fileName}
      />
    </>
  );
};

export default Embed;
